import { NftsQuery } from '@/interface/nfts'
import requester from '@/libs/request'
export const homeService = {
  getCollections() {
    return requester({
      url: '/nft/v1/list/collection',
      method: 'get',
    })
  },
  getNfts(data: NftsQuery) {
    return requester({
      url: '/nft/commodity/v2/commodity/page/query',
      method: 'post',
      data,
    })
  },
  getQtNfts(albumId) {
    return requester({
      url: `/nft/commodity/v1/statics/sell/${albumId}`,
      method: 'get',
    })
  },
  getPost(data = {}) {
    return requester({
      url: '/blog/v1/page/query',
      method: 'post',
      data: {
        categoryId: 0,
        keyWord: '',
        status: 1,
        ...data,
      },
    })
  },
  // 首页统计
  getStatistic() {
    return requester({
      url: '/nft/statistic/v1/home',
      method: 'get',
    })
  },
  // 交易记录 列表
  getCollectionsBuyer(data: any) {
    return requester({
      url: '/nft/statistic/v1/collector/volume',
      method: 'post',
      data,
    })
  },
  // 单个合约 列表
  nftRanking(data: any) {
    return requester({
      url: '/nft/statistic/v1/collector/ranking',
      method: 'post',
      data,
    })
  },
  // 空投接口
  airdrop(data: any) {
    return requester({
      url: '/nft/commodity/v1/airdrop',
      method: 'post',
      data,
    })
  },
  // 空投接口
  airdropList(data: any) {
    return requester({
      url: '/nft/commodity/v1/airdrop/page',
      method: 'post',
      data,
    })
  },
  // 前台用户查询nft空投状态
  airdropStatus({ nftId, address }) {
    return requester({
      url: `/nft/purchase/v1/commodity/airdrop/status/${nftId}/${address}`,
      method: 'get',
    })
  },
  // 单个查询NFT商品
  nftQuery({ nftId }) {
    return requester({
      url: `/nft/commodity/v1/commodity/query/${nftId}`,
      method: 'get',
    })
  },
  // 单个查询NFT商品
  airDropingCount({ albumId, address }) {
    return requester({
      url: `/nft/purchase/v1/commodity/airDroping-count/${albumId}/${address}`,
      method: 'get',
    })
  },
}
