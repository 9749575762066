import requester from '@/libs/request'
export const walletService = {
  getRadomNoByAddress: (address: string) =>
    requester({
      url: `/wallet/v1/nonce/${address}`,
      method: 'get',
    }),

  login: (data: any) =>
    requester({
      url: '/wallet/v1/login',
      method: 'post',
      data,
    }),
}
