import requester from '@/libs/request'
const TEST_DOMAIN = process.env.VUE_APP_TEST_DOMAIN as string

export const userService = {
  updateProfile: (data: any) =>
    requester({
      url: '/user/basic/v1/update',
      method: 'post',
      data,
    }),
  getShopInfo: () => {
    const shopName = TEST_DOMAIN || window.location.host.split('.')[0]
    return requester({
      url: `/shop/v1/query/name/${shopName}`,
      method: 'get',
    })
  },
  getSeller: (userCode: string | number) => {
    return requester({
      url: `/user/basic/v1/query/${userCode}`,
      method: 'get',
    })
  },
  // 用户支付方式 卡列表
  methodList: (userCode: string | number) => {
    return requester({
      url: `/payment/v1/method/list/${userCode}`,
      method: 'get',
    })
  },
  paymentSave: (data: any) =>
    requester({
      url: '/payment/v1/account/save',
      method: 'post',
      data,
    }),
}
