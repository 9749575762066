import requester from '@/libs/request'
export const rongImService = {
  getUseRongCloudToken() {
    return requester({
      url: '/actor/getUseRongCloudToken',
      method: 'get',
    })
  },
  /**
   * @param {*} userCode
   * @param {*} identity 1-卖家 2-会员  默认卖家，非必填
   * @return {*}
   */
  getRelation() {
    return requester({
      url: '/friend/relation/list?identity=2',
      method: 'get',
    })
  },
}
