import requester from '@/libs/request'
import { nftDetails, trade } from '@/interface/nfts'
export const nftService = {
  // nft 详情
  nftDetails(data: nftDetails) {
    return requester({
      url: `/nft/commodity/v2/query/${data.albumId}/${data.tokenId}`,
      method: 'get',
    })
  },
  // nft 详情 列表
  tradeLst(data: trade) {
    return requester({
      url: '/trade/record/v1/page/nft/trade',
      method: 'post',
      data,
    })
  },
  // 查询nft空投状态
  checkStatus(id: string) {
    return requester({
      url: `/nft/purchase/v1/commodity/${id}`,
      method: 'get',
    })
  },
  //使用stripe购买nft
  commodity(data: any) {
    return requester({
      url: '/nft/purchase/v1/commodity',
      method: 'post',
      data,
    })
  },
  // 查询某个NFT商品的owner
  nftOwner(data: any) {
    return requester({
      url: `/nft/commodity/v1/owner/${data.album}/${data.token}`,
      method: 'get',
    })
  },

  // 查询某个合约的详情
  getNftWithAlbumId(data: any) {
    return requester({
      url: `/nft/v1/album/${data.albumId}/statics`,
      method: 'get',
    })
  },

  getStatisticHistory(id: number) {
    return requester({
      url: `/nft/statistic/v1/curr/query/${id}`,
      method: 'get',
    })
  },
  //量子微信支付接口
  getPayUrl(data) {
    return requester({
      url: `/wx-pay/v1/unified-order-v3/native`,
      method: 'post',
      data,
    })
  },
  //量子微信支付查询接口
  getPayResult(params) {
    return requester({
      url: `/wx-pay/v1/query-order-v3`,
      method: 'get',
      params,
    })
  },
}
