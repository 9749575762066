import requester from '@/libs/request'
export const postService = {
  // 点赞-取消点赞
  thumbUp(data: any) {
    return requester({
      url: `/common/v1/thumbUp/${data.id}/${data.type}`,
      method: 'get',
    })
  },
  // posts 详情
  details(id: any) {
    return requester({
      url: `/blog/v1/query/${id}`,
      method: 'get',
    })
  },
  // posts 添加评论
  commentSave(data: any) {
    return requester({
      url: '/blog/comment/v1/save',
      method: 'post',
      data,
    })
  },
  // posts 评论列表
  commentList(data: any) {
    return requester({
      url: '/blog/comment/v1/page/query',
      method: 'post',
      data,
    })
  },
  // 取消置顶
  unTop(data: any) {
    return requester({
      url: `/blog/v1/top/${data.postId}/${data.operateType}`,
      method: 'get',
    })
  },
  // 增加浏览数
  view(id: any) {
    return requester({
      url: `/blog/v1/page/view/${id}`,
      method: 'get',
    })
  },
}
